import { useEffect, useRef, useState } from "react";

import { IoShareSocialOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";

import { IoMdHeart } from "react-icons/io";
import ShareDialog from "../ProductCategory/ShareDialog";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useIndividualProductCustomOptionMutation,
  useProductDynamicPriceMutation,
} from "../../redux/individualProduct/individualProduct";
import {
  useUserAddWishMutation,
  useUserRemoveWishMutation,
  useUserWhishQuery,
} from "../../redux/MyAccount/MyAccount";
import {
  useAddToCartMutation,
  useUserCartViewQuery,
} from "../../redux/address/Addresses";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import RelatedProducts from "./RelatedProducts";
import { FiUpload } from "react-icons/fi";
import CustomChecked from "./design_checked.svg"; 
import axios from "axios";

function ProductCustomisation({ productData }) {
  const { id: paramId } = useParams();
  const [productPriceData, setProductPriceData] = useState([]);
  const [indiproductData, setIndiproductData] = useState([]);
  const [formData, setFormData] = useState({});
  const [shareModal, setShareModal] = useState(false);
  const { data: wishData, refetch } = useUserWhishQuery();
  const { data: userCartData, refetch: cartDataRefetch } =
    useUserCartViewQuery();
  console.log("Cart Data", userCartData);
  const token = localStorage.getItem("token");
  const [selectedOption, setSelectedOption] = useState("upload");
  const [fileName, setFileName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [DesignShow, setDesignShow] = useState(false);
  const [design, setDesign] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [DesignImage, setDesignImage] = useState(false);
  const [SelectedFileUrl, setSelectedFileUrl] = useState(null);
  const [FileTypeUrl, setFileTypeUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [NeedFile, setNeedFile] = useState(false);
  const [calcloading, setCalcLoading] = useState(false);
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFileChange = (event) => {
    try {
      const uploadedFile = event.target.files[0];
  
      if (uploadedFile) {
        
        const fileSize = uploadedFile.size; 
        const maxSize = 100 * 1024 * 1024; 
  
        if (fileSize > maxSize) {
          toast.error("File size exceeds the 100MB limit. Please upload a smaller file.");
          return; 
        }
  
        
        setFileName(uploadedFile.name);
        setFile(uploadedFile);
        setDesignShow(false);
  
        toast.success("File uploaded successfully!");
      }
    } catch (error) {
      console.error("Error selecting design:", error);
      toast.error("Failed to upload the file. Please try again.");
    }
  };
  
  const inPutRefs = useRef();

  const [AddWishlistApi] = useUserAddWishMutation();
  const [RemoveWishlistApi] = useUserRemoveWishMutation();
  const [ProductCustomOptionApi, { isLoading: optionsLoading }] =
    useIndividualProductCustomOptionMutation();
  const [ProductPriceApi, { isLoading: priceLoading }] =
    useProductDynamicPriceMutation();
  const [AddToCartApi, { isLoading: addToCartLoading }] =
    useAddToCartMutation();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file?.name || "");

    if (file) {
      const reader = new FileReader();
      reader.onload = () => setSelectedFileUrl(reader.result);
      reader.readAsDataURL(file);
      setFile(file);
    } else {
      setSelectedFileUrl(null);
      setFile(null);
    }
  };

  console.log("this is wishdata wishData", wishData);

  console.log("this is formData", formData);

  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const handleDesign = async () => {
    setisLoading(true);
    const header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(
        `${BASE_URL}/api/productsheet/get-my-designs/`,
        { headers: header }
      );
      const res = await response?.data;
      console.log(res);
      setDesign(res);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    handleDesign();
  }, []);

  const selected_options = {};
  console.log(indiproductData);
  Object.entries(indiproductData).map(([key, value]) => {
    if (
      Array.isArray(value) &&
      key != "product_id" &&
      key != "product_slug" &&
      key != "Product" &&
      key != "Product Category" &&
      key != "Parent Category"
    ) {
      selected_options[key] = value[0];
    } else if (
      typeof value === "string" &&
      key != "Product" &&
      key != "product_slug" &&
      key != "Product Category" &&
      key != "product_id" &&
      key != "Parent Category"
    ) {
      selected_options[key] = value;
    }
  });

  useEffect(() => {
    const selected_options = {};
    Object.entries(indiproductData).map(([key, value]) => {
      if (
        Array.isArray(value) &&
        key != "product_id" &&
        key != "Product" &&
        key != "product_slug" &&
        key != "Product Category" &&
        key != "Parent Category"
      ) {
        selected_options[key] = value[0];
      } else if (
        typeof value === "string" &&
        key != "Product" &&
        key != "product_slug" &&
        key != "Product Category" &&
        key != "product_id" &&
        key != "Parent Category"
      ) {
        selected_options[key] = value;
      }
    });

    setFormData(selected_options);
  }, [indiproductData, paramId]);

  const handleAddToWishlist = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = {
        product_slug: paramId,
        selected_options:
          Object.keys(formData).length > 0 ? formData : selected_options,
        Price: productPriceData["Price"],
        "Unit Price": productPriceData["Unit Price"],
        "Weight in KG": productPriceData["Weight in KG"],
        "Dispatch by": productPriceData["Dispatch by"],
      };

      if (token) {
        const response = await AddWishlistApi(data);
        if (response) {
          toast.success("Product added to Wishlist", {
            position: "top-center",
            autoClose: 3000,
          });
          refetch();
        } else {
          toast.error("Fail to add product in wishlist", {
            position: "top-center",
            autoClose: 3000,
          });
          refetch();
        }
      } else {
        toast.warning("Login to wishlist product", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function compareObjects(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  console.log(formData);

  const handleRemoveFromWishlist = async () => {
    try {
      const token = localStorage.getItem("token");
      const objectToCompare = Object.keys(formData)?.length
        ? formData
        : selected_options;
      const removeObject = wishData?.filter(
        (i) =>
          i?.product_slug === productData?.slug &&
          compareObjects(objectToCompare, i?.product_properties)
      );
      if (token) {
        await RemoveWishlistApi(removeObject[0]?.wishlist_id);
        toast.success("Product removed from Wishlist", {
          position: "top-center",
          autoClose: 3000,
        });

        refetch();
      } else {
        toast.warning("Please Login", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selected_options);

  const handleAddToCart = async () => {
    if(productPriceData?.Price === undefined) {
      toast.error("Please click \"calculate\" before adding the product to the cart.", {
        position: "top-center",
        autoClose: 3000,
      });
    }else{
    try {
      // if (!token) {
      //   toast.warning("Please Login", {
      //     position: "top-center",
      //     autoClose: 3000,
      //   });
      //   return;
      // }

      console.log(selected_options);

      const cartData = {
        product_slug: paramId,
        selected_options: { ...formData },
        Price: productPriceData["Price"],
        "Unit Price": productPriceData["Unit Price"],
        "Weight in KG": productPriceData["Weight in KG"],
        "Dispatch by": productPriceData["Dispatch by"],
      };

      const formData1 = new FormData();
      formData1.append("product_slug", cartData.product_slug);
      formData1.append(
        "selected_options",
        JSON.stringify(cartData.selected_options)
      );
      formData1.append("Price", cartData.Price);
      formData1.append("Unit Price", cartData["Unit Price"]);
      formData1.append("Weight in KG", cartData["Weight in KG"]);
      formData1.append("Dispatch by", cartData["Dispatch by"]);
      formData1.append("design_file", file);
      formData1.append("design_file_url", SelectedFileUrl ?? "");
      // formData1.append("design_choice", selectedOption);

      if (priceLoading) {
        toast.warning("Please Wait", {
          position: "top-center",
          autoClose: 3000,
        });
      }

      await cartDataRefetch();
      const duplicateInCart = userCartData?.cart_data?.some(
        (i) =>
          i?.product_id === paramId &&
          compareObjects(formData, i?.product_properties)
      );

      if (!duplicateInCart && !priceLoading) {
        const response = await AddToCartApi(formData1);
        if (response?.data) {
          toast.success("Product Added to Cart", {
            position: "top-center",
            autoClose: 3000,
          });
          setIsModalOpen(true);
        } else {
          toast.error("Fail to add product in cart", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } else {
        if (duplicateInCart) {
          toast.error("Product Already in cart", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  };

  useEffect(() => {
    const getProductCustomOptions = async () => {
      try {
        const productBody = {
          product_slug: paramId,
        };

        const response = await ProductCustomOptionApi(productBody);

        if (response) {
          setIndiproductData(response?.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getProductCustomOptions();
  }, [paramId]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    
    // Convert 'Width in inches' and 'Length in inches' to integers
    if (name === "Width in inches" || name === "Length in inches") {
      value = parseFloat(value);
    }
  
    // Convert Quantity to integer
    if (name === "Quantity") {
      value = parseInt(value);
    }
  
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    // const test = ProductPriceApi.mutate();
    // console.log("Testing mutate", test);
  }, [paramId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  console.log("this is productData", productData);

  // useEffect(() => {
  //   const getProductDynamicPrice = async () => {
  //     try {
  //       const productPriceLoad = {
  //         product_slug: productData?.slug,
  //         selected_options:
  //           Object.keys(formData)?.length > 0 ? formData : selected_options,
  //       };
  
  //       const response = await ProductPriceApi(productPriceLoad);
  //       console.log("This is price response:", response);
  
  //       if (response) {
          
  //         const responseSelectedOptions = response?.data?.selected_options;
  
  //         const isMatch = JSON.stringify(responseSelectedOptions) === JSON.stringify(productPriceLoad.selected_options);
  
  //         if (isMatch) {
  //           setProductPriceData(response?.data?.price_details);
  //         } else {
  //           console.warn("Selected options do not match the request payload. Data will not be updated.");
  //         }
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  
  //   if (productData) getProductDynamicPrice();
  // }, [productData, formData]);
  // useEffect(() => {
  //   const getProductDynamicPrice = async () => {
  //     try {
  //       const productPriceLoad = {
  //         product_slug: productData?.slug,
  //         selected_options: Object.keys(formData)?.length > 0 ? formData : selected_options, // Default selection on load
  //       };
  
  //       const response = await ProductPriceApi(productPriceLoad);
  //       console.log("This is price response:", response);
  
  //       if (response) {
  //         setProductPriceData(response?.data?.price_details);
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  
  //   if (productData) getProductDynamicPrice();
  // }, [productData]);

  const calculatePrice = async () => {
    try {
      setCalcLoading(true);
      const productPriceLoad = {
        product_slug: productData?.slug,
        selected_options:
          Object.keys(formData)?.length > 0 ? formData : selected_options,
      };
  
      const response = await ProductPriceApi(productPriceLoad);
      console.log("This is price response:", response);
  
      if (response) {
        const responseSelectedOptions = response?.data?.selected_options;
        const isMatch =
          JSON.stringify(responseSelectedOptions) ===
          JSON.stringify(productPriceLoad.selected_options);
  
        if (isMatch) {
          setProductPriceData(response?.data?.price_details);
          setCalcLoading(false);
        } else {
          console.warn(
            "Selected options do not match the request payload. Data will not be updated."
          );
          setCalcLoading(false);
        }
      }
    } catch (err) {
      setCalcLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    console.log('Updated formData:', formData);
    // Rest of your price update logic
  }, [formData]);
  

  const [scrolled, setScrolled] = useState(false);
  const [scrollThreshold, setScrollThreshold] = useState(0);
  const divRef = useRef(null);
  // const formRef = useRef(null);

  useEffect(() => {
    function calculateScrollThreshold() {
      if (divRef.current) {
        const height = divRef.current.offsetHeight;
        setScrollThreshold(height);
      }
      const container = document.getElementById("myForm");
      // console.log("Container", container);
      const viewportHeight = container?.offsetHeight;
      const dynamicThreshold = viewportHeight;
      // setScrollThreshold(dynamicThreshold);
    }

    function checkScroll() {
      if (window.scrollY > scrollThreshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    calculateScrollThreshold();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [scrollThreshold, divRef?.current?.offsetHeight]);

  console.log("this is productData data", productData);

  console.log(wishData);

  const handleSelectDesign = (items) => {
    try {
    
      if (items?.design_file) {
        const fileSize = items.design_file.size; 
        const maxSize = 100 * 1024 * 1024; 
  
        if (fileSize > maxSize) {
          toast.error("File size exceeds the 100MB limit. Please upload a smaller file.");
          return; 
        }
      }
  
      setSelectedFileUrl(items?.design_file);
      setFileName(items?.design_file_name);
      setFileTypeUrl("url");
      setDesignShow(false);
  
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error("Error selecting design:", error);
      toast.error("Failed to upload the file. Please try again.");
    }
  };

  return (
    <div id="mainDiv" className="w-full md:flex md:flex-col md:gap-y-[10px]">
      <div className="flex w-full justify-between mb-[10px] md:mb-0">
        <h1 className="text-[36px] font-medium md:text-[20px] md:font-semibold">
          {productData?.title}
        </h1>
        <div className="flex text-[32px] text-custom-text">
          <button
            className="mr-[12px]"
            onClick={() => setShareModal(!shareModal)}
          >
            <IoShareSocialOutline />
          </button>
          <ShareDialog
            visibility={shareModal}
            setVisibility={setShareModal}
            form={"individual"}
          />
          <button
            disabled={optionsLoading || priceLoading}
            className={`${
              optionsLoading || priceLoading
                ? "text-[#00a4db63]"
                : "text-custom-text"
            }`}
          >
            {wishData &&
            wishData?.message !== "Wishlist is empty" &&
            wishData?.some(
              (product) =>
                product?.product_id === productData?.id &&
                compareObjects(
                  Object?.keys(formData)?.length ? formData : selected_options,
                  product?.product_properties
                )
            ) ? (
              <IoMdHeart
                className={`${
                  optionsLoading || priceLoading
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => handleRemoveFromWishlist()}
              />
            ) : (
              <IoMdHeartEmpty
                className={`${
                  optionsLoading || priceLoading
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => handleAddToWishlist()}
              />
            )}
          </button>
        </div>
      </div>

      {priceLoading ? (
        <div className="pb-3 flex pl-8  gap-3 text-black">
          <div className="dot-progress-loader">
            {" "}
            <p style={{ whiteSpace: "nowrap" }} className="pl-[40px] mt-[-7px]">
              We are calculating your price
            </p>
          </div>
        </div>
      ) : (
        <h1 className="text-[36px] text-custom-text-green md:text-[20px] md:font-semibold">
        {productPriceData?.Price !== undefined ? (
          `₹ ${productPriceData.Price}`
        ) : (
          <span className="text-red-500 text-[18px] md:text-[16px]">
            Select specifications, click "Calculate" and get your price instantly!
          </span>
        )}
      </h1>
      )}
      {priceLoading ? (
        <div></div>
      ) : (
        <div className="text-[#2F2F2F] text-[20px] font-medium md:text-[14px] md:font-normal">
          {productPriceData?.["Unit Price"] &&
            `Unit Price ₹ ${productPriceData["Unit Price"]}`}
        </div>
      )}
      {productPriceData?.["Dispatch by"] ===  undefined ? (
        <div></div>
      ) : (
        <div className="text-[20px] md:text-[14px] md:font-normal">{`Dispatched by  ${productPriceData?.["Dispatch by"]}`}</div>
      )}
        <button
          className="bg-[#a7c539] text-white py-[10px] px-[24px] rounded-[4px] transition delay-300 w-fit md:w-full md:text-center hover:cursor-pointer flex md:hidden mt-3"
          onClick={calculatePrice}
          disabled={calcloading}
        >
          {calcloading ? <div>Loading</div> : <div>Calculate</div>}
        </button>
      <form
        ref={divRef}
        className="mt-[-70px] md:mt-5 md:flex md:flex-col md:gap-y-1"
      >
        {indiproductData ? (
          Object.entries(indiproductData).map(([key, value]) => (
            <div
              key={key}
              className="flex flex-row flex-nowrap md:flex-wrap md:my-2  justify-between items-center mb-[32px] md:mt-[-5px] md:mb-0 md:gap-y-2"
            >
              {(Array.isArray(value) || typeof value === "string") &&
                key !== "product_id" &&
                key !== "product_slug" &&
                key !== "Product" &&
                key !== "Product Category" &&
                key !== "Parent Category" && (
                  <label htmlFor={key} className="md:w-full font-semibold md:mt-3">
                    {key}
                  </label>
                )}

              {Array.isArray(value) && value?.length > 3 ? (
                <select
                  name={key}
                  className="border-[#949494] border outline-none py-[8px] px-[10px] rounded-[8px] w-[60%] md:w-full"
                  onChange={(e) => handleChange(e)}
                >
                  {value.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="w-[85%] md:w-full flex flex-row flex-wrap justify-end md:justify-start ">
                  {Array.isArray(value) &&
                    value.map((option) =>
                      option ? (
                        <button
                          key={option}
                          type="button"
                          onClick={() =>
                            setFormData({ ...formData, [key]: option })
                          }
                          style={{ margin: "5px", marginBottom: "10px" }}
                          className={`rounded-[8px] py-[8px] px-[10px] mr-[16px] mb-[16px] transition duration-300 hover:border-[#949494] hover:text-black ${
                            formData[key] === option
                              ? "border-[#949494] border text-black"
                              : "border border-[#DDDDDE] text-[#949494]"
                          }`}
                        >
                          {option}
                        </button>
                      ) : null
                    )}
                </div>
              )}

              {typeof value === "string" &&
                key !== "Product" &&
                key !== "product_slug" &&
                key !== "Product Category" &&
                key != "product_id" &&
                key !== "Parent Category" && (
                  <button
                    key={value}
                    type="button"
                    onClick={() => setFormData({ ...formData, [key]: value })}
                    style={{ margin: "5px" }}
                    className={`w-[85%] md:w-full rounded-[8px] py-[8px] px-[10px] mr-[16px] mb-[16px] transtion duration-300 hover:border-[#949494] hover:text-black ${
                      formData[key] === value
                        ? "border-[#949494] border text-black"
                        : "border border-[#DDDDDE] text-[#949494]"
                    }`}
                  >
                    {value}
                  </button>
                )}
            </div>
          ))
        ) : (
          <p className="text-[25px] text-[#2f2f2f] font-[600] py-4">
            No Customization Data Found
          </p>
        )}

        <div className="hidden md:block">
          {scrolled && (
            <div
              className="bg-custom-btn text-white py-[10px] px-[24px] rounded-[4px] transition delay-300 w-fit md:w-full md:text-center hover:cursor-pointer "
              onClick={() => handleAddToCart()}
            >
              {addToCartLoading ? <div>Loading</div> : <div>Add to Cart</div>}
            </div>
          )}  
        </div>
        <div className="hidden md:block">
          {scrolled && (
            <button
              className="bg-green-500 text-white py-[10px] px-[24px] rounded-[4px] transition delay-300 w-fit md:w-full md:text-center hover:cursor-pointer "
              onClick={calculatePrice}
              disabled={calcloading}
            >
              {calcloading ? <div>Loading</div> : <div>Calculate</div>}
            </button>
          )}  
        </div>
        <input
          type="file"
          className="hidden"
          ref={inPutRefs}
          onChange={handleFileChange}
        />
        <div className="w-[100%] flex md:flex-col gap-[150px] md:gap-[20px] mb-11">
          <p className="pt-1 font-[700] inline-block min-w-[150px]">
            Have a design?
          </p>
          <div className="flex flex-wrap flex-col justify-start md:justify-end gap-2">
            <div className="flex items-center gap-1">
              <input
                type="radio"
                name="design"
                value="upload"
                className="special_input2"
                checked={selectedOption === "upload"}
                onChange={handleRadioChange}
              />
              <p>Upload</p>
              <div className="flex ml-4 items-center">
                <p
                  onClick={() => setDesignShow(!DesignShow)}
                  className="py-[7px] gap-1 cursor-pointer px-[10px] border-[1px] border-blue rounded flex items-center"
                >
                  Browse <FiUpload className="text-custom-text" />
                </p>
                <p className="pl-1">{fileName || "No file chosen"}</p>
              </div>
            </div>
            <div className="flex-col items-center gap-1">
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  name="design"
                  value="email"
                  className="special_input2"
                  checked={selectedOption === "email"}
                  onChange={handleRadioChange}
                />
                <p>Email Your Design</p>
              </div>
              {selectedOption === "email" && (
                <div className="flex gap-1 w-[100%]">
                  <img src={CustomChecked} className="w-[30px] h-[30px]" />
                  <p className="font-[400]">
                    You can email your design files after placing your order.
                    Please mention your order number in the email subject line
                    for faster processing.
                  </p>
                </div>
              )}
            </div>
            <div className="w-[150px]">
              <p
                onClick={() => setNeedFile(true)}
                className="text-custom-text border-b-[1px] border-blue cursor-pointer"
              >
                Design File Checklist
              </p>
            </div>
          </div>
        </div>

        <div
          className="bg-custom-btn text-white py-[10px] px-[24px] rounded-[4px] transition delay-300 w-fit md:w-full md:text-center hover:cursor-pointer flex md:hidden"
          onClick={() => handleAddToCart()}
        >
          {addToCartLoading ? <div>Loading</div> : <div>Add to Cart</div>}
        </div>

        {isModalOpen && (
        <div className="fixed inset-0 bg-slate-700 bg-opacity-50 md:bg-slate-50 md:bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Product Added to Cart</h2>
            <div className="flex justify-around">
              <button
                className="border-2 border-[#00a6db]  text-black py-2 px-4 rounded mr-2"
                onClick={() => {
                  setIsModalOpen(false);
                  navigate("/account/cart");
                }}
              >
                Go to Cart
              </button>
              <button
                className="bg-[#00a6db] text-white py-2 px-4 rounded"
                onClick={() => setIsModalOpen(false)}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      )}
      </form>

      {!scrolled && (
        <div
          id="stickyDiv"
          className={`hidden md:w-full md:flex md:flex-col md:gap-y-3 py-4 items-center md:sticky bottom-0  bg-white shadow-[0px_-2px_2px_0px_rgba(0,_166,_219,_0.20)]`}
        >
          <div className="flex flex-row w-full justify-between items-center">
            {priceLoading ? (
              <div className="pl-8 pb-3">
                <div className="dot-progress-loader"></div>
              </div>
            ) : (
              <h1 className="text-[36px] text-custom-text-green md:text-[20px] md:font-semibold">
                {" "}
                {` ₹ ${productPriceData?.Price}`}
              </h1>
            )}
            <div className="text-[16px] font-normal text-[#949494]">
              GST not included
            </div>
          </div>

          <div
            onClick={() => handleAddToCart()}
            className="w-full bg-[#00A6DB] flex justify-center text-white text-[12px] font-semibold py-[10px] rounded-[4px]"
          >
            {/* Add to cart */}
            {addToCartLoading ? <p>Loading..</p> : "Add to cart"}
          </div>
        </div>
      )}
      <div className="w-full md:mt-[-50px] pt-9">
        <RelatedProducts productData={productData} />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />

      {DesignShow && (
        <div className="modal-overlay">
          <div className="w-[100%] h-[100%] flex justify-center items-center">
            <div className="w-[445px] md:w-[100%] md:mx-3 bg-white rounded py-[20px] px-[15px]">
              <div className="w-[100%] flex justify-end">
                <svg
                  className="cursor-pointer"
                  onClick={() => setDesignShow(!DesignShow)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.59967 11.334L7.99967 8.93398L10.3997 11.334L11.333 10.4007L8.93301 8.00065L11.333 5.60065L10.3997 4.66732L7.99967 7.06732L5.59967 4.66732L4.66634 5.60065L7.06634 8.00065L4.66634 10.4007L5.59967 11.334ZM7.99967 14.6673C7.07745 14.6673 6.21079 14.4922 5.39967 14.142C4.58856 13.7918 3.88301 13.3169 3.28301 12.7173C2.68301 12.1173 2.20812 11.4118 1.85834 10.6007C1.50856 9.78954 1.33345 8.92287 1.33301 8.00065C1.33301 7.07843 1.50812 6.21176 1.85834 5.40065C2.20856 4.58954 2.68345 3.88398 3.28301 3.28398C3.88301 2.68398 4.58856 2.2091 5.39967 1.85932C6.21079 1.50954 7.07745 1.33443 7.99967 1.33398C8.9219 1.33398 9.78856 1.5091 10.5997 1.85932C11.4108 2.20954 12.1163 2.68443 12.7163 3.28398C13.3163 3.88398 13.7915 4.58954 14.1417 5.40065C14.4919 6.21176 14.6668 7.07843 14.6663 8.00065C14.6663 8.92287 14.4912 9.78954 14.141 10.6007C13.7908 11.4118 13.3159 12.1173 12.7163 12.7173C12.1163 13.3173 11.4108 13.7924 10.5997 14.1427C9.78856 14.4929 8.9219 14.6678 7.99967 14.6673ZM7.99967 13.334C9.48856 13.334 10.7497 12.8173 11.783 11.784C12.8163 10.7507 13.333 9.48954 13.333 8.00065C13.333 6.51176 12.8163 5.25065 11.783 4.21732C10.7497 3.18398 9.48856 2.66732 7.99967 2.66732C6.51079 2.66732 5.24967 3.18398 4.21634 4.21732C3.18301 5.25065 2.66634 6.51176 2.66634 8.00065C2.66634 9.48954 3.18301 10.7507 4.21634 11.784C5.24967 12.8173 6.51079 13.334 7.99967 13.334Z"
                    fill="#ADADAD"
                  />
                </svg>
              </div>
              <p className="font-[600] text-center pt-1 pb-2 text-[#2F2F2F] text-[20px]">
                Upload design
              </p>
              <div className="w-[100%] flex items-center justify-between gap-3 mt-1">
                <button
                  onClick={() => inPutRefs.current.click()}
                  className="px-1 w-[100%] h-[39px] border-[1px] border-blue bg-white text-custom-text rounded"
                >
                  Browse
                </button>
                {design?.results?.length === 0 || !token ? (
                  ""
                ) : (
                  <button
                    onClick={() => setDesignImage(!DesignImage)}
                    className="px-1 w-[100%] h-[39px] bg-custom-btn text-white rounded"
                  >
                    {DesignImage ? "Close" : "Upload from Saved"}
                  </button>
                )}
              </div>
              <div className="w-[100%] mt-2 max-h-[220px] overflow-y-scroll">
                {DesignImage &&
                  (design?.results?.length === 0 ? (
                    <p>No Saved Design</p>
                  ) : (
                    design?.results?.map((item, index) => (
                      <div
                        onClick={() => handleSelectDesign(item)}
                        className="max-w-[100%] rounded  border-[1px] border-[#DDDDDE] py-[24px] px-[12px] text-wrap break-words cursor-pointer"
                      >
                        <p className="text-wrap font-[600]">
                          {item?.design_file_name}
                        </p>
                        <p className="pt-[2px]">{item?.created_at}</p>
                      </div>
                    ))
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {NeedFile === true && (
        <div className="modal-overlay">
          <div className="w-[100%] flex justify-center items-center">
            <div className="w-[600px]  min-h-[421px] rounded bg-white py-3 px-3">
              <div className="w-[100%] flex justify-between items-center pb-2">
                <p className="font-[600] text-center pt-1 pb-2 text-[#2F2F2F] text-[20px]">
                  Upload design
                </p>
                <svg
                  className="cursor-pointer"
                  onClick={() => setNeedFile(!NeedFile)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.59967 11.334L7.99967 8.93398L10.3997 11.334L11.333 10.4007L8.93301 8.00065L11.333 5.60065L10.3997 4.66732L7.99967 7.06732L5.59967 4.66732L4.66634 5.60065L7.06634 8.00065L4.66634 10.4007L5.59967 11.334ZM7.99967 14.6673C7.07745 14.6673 6.21079 14.4922 5.39967 14.142C4.58856 13.7918 3.88301 13.3169 3.28301 12.7173C2.68301 12.1173 2.20812 11.4118 1.85834 10.6007C1.50856 9.78954 1.33345 8.92287 1.33301 8.00065C1.33301 7.07843 1.50812 6.21176 1.85834 5.40065C2.20856 4.58954 2.68345 3.88398 3.28301 3.28398C3.88301 2.68398 4.58856 2.2091 5.39967 1.85932C6.21079 1.50954 7.07745 1.33443 7.99967 1.33398C8.9219 1.33398 9.78856 1.5091 10.5997 1.85932C11.4108 2.20954 12.1163 2.68443 12.7163 3.28398C13.3163 3.88398 13.7915 4.58954 14.1417 5.40065C14.4919 6.21176 14.6668 7.07843 14.6663 8.00065C14.6663 8.92287 14.4912 9.78954 14.141 10.6007C13.7908 11.4118 13.3159 12.1173 12.7163 12.7173C12.1163 13.3173 11.4108 13.7924 10.5997 14.1427C9.78856 14.4929 8.9219 14.6678 7.99967 14.6673ZM7.99967 13.334C9.48856 13.334 10.7497 12.8173 11.783 11.784C12.8163 10.7507 13.333 9.48954 13.333 8.00065C13.333 6.51176 12.8163 5.25065 11.783 4.21732C10.7497 3.18398 9.48856 2.66732 7.99967 2.66732C6.51079 2.66732 5.24967 3.18398 4.21634 4.21732C3.18301 5.25065 2.66634 6.51176 2.66634 8.00065C2.66634 9.48954 3.18301 10.7507 4.21634 11.784C5.24967 12.8173 6.51079 13.334 7.99967 13.334Z"
                    fill="#ADADAD"
                  />
                </svg>
              </div>
              <p>Design tips for better print</p>
              <p>
                • Design with 300 dpi resolution for artwork and images. <br />{" "}
                • Design in CMYK color settings only. <br />• Use vector arts.{" "}
                <br />• Convert text to curves (no font should be present in
                Artwork). <br />• Provide a bleed of 3 mm on all sides. <br />•
                Please use 3 to 7 mm safe area from cut marks. <br />• For black
                text set K=100 and C,M & Y=0 in CMYK color setting. <br />{" "}
                <br /> It would be best you can share Press Ready PDF (High
                Resolution Vector PDF Artwork). <br />{" "}
                <span className="text-red-600">
                  Only pdf, indesign, illustrator, CDR, EPS, SVG files accepted
                </span>{" "}
                <br />{" "}
                <a href="/contactus" className="text-blue-400">
                  Contact Us
                </a>{" "}
                If you need more help.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductCustomisation;
